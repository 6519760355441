export const APP_VERSION = process.env.INSTORE_VERSION || "development"
export const SENTRY_DSN = process.env.SENTRY_DSN
export const hour12Markets = ["US", "AU", "CA"]

type Environments = "PROD" | "PPE" | "AUTOMATED_TEST" | "LOCALHOST"
export type Locations = {
  markets: string[]
  stores: string[]
  kiosks: string[]
}

export const VisualVariationTypes = ["colour", "clothing", "cover"]

export function isEnvironment(env: Environments): boolean {
  if (process.env.ENV === "development") {
    // console.log('Upptäcka running on localhost.')
    return env === "LOCALHOST"
  } else if (process.env.ENV === "production") {
    // console.log('Upptäcka running in production.')
    return env === "PROD"
  } else if (process.env.ENV === "preproduction") {
    // console.log('Upptäcka running in pre-production.')
    return env === "PPE"
  } else if (process.env.NODE_ENV === "test") {
    // console.log('Upptäcka running in automated test mode.')
    return env === "AUTOMATED_TEST"
  }

  // eslint-disable-next-line no-console
  console.log("Upptäcka running in confused mode.")
  return false
}

export const PRODUCT_COLOR = {
  beige: "10003",
  black: "10139",
  gray: "10028",
}

export const PRODUCT_REGEX = new RegExp(/^\d{8}$/)
export const PLANNER_REGEX = new RegExp(/^(?=.*[A-Za-z].*)[A-Za-z0-9]{6,7}$/)

export const FULLSERVE_PHONE_REQUIRED = ["014", "471", "589"]
export const FULLSERVE_DISABLED_MARKETS: string[] = []
export const FULLSERVE_DISABLED_STORES: string[] = []

export const READY_FOR_PICKUP_STATUSES = [
  "PICKED",
  "CHECKING",
  "CHECKED",
  "COMPLETED",
]
export const PREPARING_ORDER_STATUSES = [
  "PLANNED",
  "READY_TO_ASSIGN",
  "PICKING_STARTED",
  "PICKING",
]

export const FULLSERVE_HIDE_PICKING_STATUS: string[] = []

export const HIDE_IKEA_FAMILY = ["AT", "DE", "NL", "PL"]

export const HIDE_HANDOVER_TO_ONLINE: string[] = []
export const HIDE_HANDOVER_TO_SALJA: string[] = ["CN"]
export const BYPASS_ONETRUST_MARKETS: string[] = ["CN"]

export const RUN_OPTIMIZELY = false
export const OPTIMIZELY_DISABLED: string[] = ["CN"]

export const MAX_QUANTITY = 100
export const MAX_QUANTITY_FULLSERVE = 10

export const COOKIE_INFO_MARKETS = ["AT"]

export const ENABLED_WOOD_INFO_MARKETS = ["CH"]

export const DO_NOT_DIY_MARKETS = ["AU"]

//#region Online store

// enabled if online=true is in query params
export const ONLINE_STORE =
  new URL(document.location.toString()).searchParams.get("online") === "true"

//#endregion

//#region CAAS

// enabled if caas=true is in query params
export const CAAS_ENABLED =
  new URL(document.location.toString()).searchParams.get("caas") === "true"

// NOTE! This is only for mobile entrypoints! There's a corresponding flag in the upptäcka repo for Kiosk entrypoint!
export const ENABLED_CAAS_LOCATIONS: Locations = {
  markets: [],
  stores: ["445"],
  kiosks: [],
}

//#endregion

//#region Coworker Survey

export const HIDE_COWORKER_SURVEY = ["SI", "DE"]
// ENABLE COWORKER SURVEY FOR ALL STORES WHERE WE RUN MULTIPLE ORDERS TEST
export const SHOW_COWORKER_SURVEY_STORES: string[] = [
  "113",
  "262",
  "263",
  "267",
]

//#endregion

//#region CO360

// USE CO360 FOR ORDER RETRIVAL
export const ENABLED_CO360_LOCATIONS: Locations = {
  markets: [],
  stores: [],
  kiosks: [],
}
// NOT AVAILABLE IN CN
export const DISABLED_CO360_MARKETS = ["CN"]
// DISABLE CO360 FOR ALL STORES WHERE WE RUN MULTIPLE ORDERS TEST
export const DISABLED_CO360_STORES: string[] = []

export const CO360_ENABLED =
  new URL(document.location.toString()).searchParams.get("co360") === "true"

//#endregion

//#region  ISOM orders

export const ISOM_ORDERS_ENABLED: Locations = {
  //must be in uppercase
  markets: ["AU", "NL", "FI", "HU", "AT"],
  stores: ["373", "582", "522"],
  kiosks: ["UKID.HR.021.E8"],
}

//#endregion

//#region  Edit orders

export const EDIT_ORDERS_ENABLED =
  new URL(document.location.toString()).searchParams.get("edit") === "true"

export const EDIT_ORDERS_LOCATIONS: Locations = {
  markets: ["NL", "AU", "FI", "HU", "AT"],
  stores: ["522"],
  kiosks: [],
}

//#endregion

//#region Checkout

export const CHECKOUT_LOCATIONS: Locations = {
  markets: [],
  stores: ["507"],
  kiosks: [],
}

export const CHECKOUT_ENABLED =
  new URL(document.location.toString()).searchParams.get("checkout") === "true"

//#endregion

//#region Capture email or SMS
export const CAPTURE_SMS_EMAIL_LOCATIONS: Locations = {
  //must be in uppercase
  markets: [],
  stores: [],
  kiosks: [],
}
export const CAPTURE_SMS_EMAIL_ENABLED =
  new URL(document.location.toString()).searchParams.get("capture") === "true"
//#endregion

//#region Capture customer profile
export const CAPTURE_PROFILE_LOCATIONS: Locations = {
  //must be in uppercase
  markets: [],
  stores: [],
  kiosks: [],
}
export const CAPTURE_PROFILE_ENABLED =
  new URL(document.location.toString()).searchParams.get("profile") === "true"
//#endregion

//#region Scanner
export const DISABLED_SCANNER_MARKETS = ["CN"]

// enabled if scanner=true is in query params
export const SCANNER_ENABLED =
  new URL(document.location.toString()).searchParams.get("scanner") === "true"

export const ENABLED_SCANNER_LOCATIONS: Locations = {
  markets: [],
  stores: [],
  kiosks: [],
}

//#endregion

//#region Waiting times
export const WAITING_TIME_LOCATIONS: Locations = {
  //must be in uppercase
  markets: [],
  stores: ["507"],
  kiosks: [],
}

export const WAITING_TIME_ENABLED =
  new URL(document.location.toString()).searchParams.get("time") === "true"
//#endregion

//#region App promotion

export const APP_PROMOTION_DISABLED_MARKETS: string[] = ["CZ", "FI", "SK"]

//#endregion

//#region Time slot management

export const TSM_ENABLED =
  new URL(document.location.toString()).searchParams.get("tsm") === "true"

export const TSM_LOCATIONS: Locations = {
  markets: [],
  stores: ["507"],
  kiosks: [],
}

export const TSM_UPDATE_ENABLED =
  new URL(document.location.toString()).searchParams.get("tsm-update") ===
  "true"

export const TSM_UPDATE_LOCATIONS: Locations = {
  markets: [],
  stores: ["507"],
  kiosks: [],
}

//#endregion

export const allowedFeatureFlags = [
  "online",
  "caas",
  "co360",
  "scanner",
  "edit",
  "time",
  "app",
  "capture",
  "tsm",
  "tsm-update",
  "checkout",
]

export const HIDE_LANGUAGE_IN_STORE = [
  { storeNo: "339", hiddenLangue: ["ca", "eu", "gl"] },
]
