/**
 * Check if child is of type ReactElement
 */
export const isComponent = <P>(
  child: unknown,
): child is React.ReactElement<P> =>
  child !== undefined &&
  typeof child === "object" &&
  child !== null &&
  "type" in child
